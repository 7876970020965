import React, { Component } from 'react';
import styled from 'styled-components';


export const Table = styled.table`
width: 100%;,
  border: 1px solid black;

  tr:nth-child(odd)    { background-color:#eee; }
tr:nth-child(even)    { background-color:#fff; }
margin-top: 1em;
`;

const Wrapper = styled.div`
 height: 100%
`;

class ZoneCalculator extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { value: 100 };
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  getRawMarkup(val) {

    return Math.round(this.state.value * val);
  }

  render() {
    return (
      <Wrapper>
        Threshold &nbsp;
  
        <input
        type="number"
          id="ftp"
          onChange={this.handleChange}
          defaultValue={this.state.value}
        />
       
 
<Table>
  <tr>
    <th>Zone</th>
    <th>From</th>
    <th>To</th>
  </tr>
  <tr>
    <td><strong>1a</strong></td>
    <td>0</td>
    <td>10</td>
  </tr>
  <tr>
    <td><strong>1b</strong></td>
    <td>11</td>
    <td >{this.getRawMarkup(0.45)}</td>
  </tr>
  <tr>
    <td><strong>1c</strong></td>
    <td>{this.getRawMarkup(0.45)+1}</td>
    <td>{this.getRawMarkup(0.56)-1}</td>
  </tr>
  <tr>
    <td><strong>2</strong></td>
    <td>{this.getRawMarkup(0.56)}</td>
    <td>{this.getRawMarkup(0.76)-1}</td>
  </tr>
  <tr>
    <td><strong>3</strong></td>
    <td>{this.getRawMarkup(0.76)}</td>
    <td>{this.getRawMarkup(0.91)-1}</td>
  </tr>
  <tr>
    <td><strong>4</strong></td>
    <td>{this.getRawMarkup(0.91)}</td>
    <td>{this.getRawMarkup(1.06)-1}</td>
  </tr>
  <tr>
    <td><strong>5</strong></td>
    <td>{this.getRawMarkup(1.06)}</td>
    <td>{this.getRawMarkup(1.21)-1}</td>
  </tr>
  <tr>
    <td><strong>6</strong></td>
    <td>{this.getRawMarkup(1.21)}</td>
    <td>2000</td>
  </tr>
</Table>
      </Wrapper>
    );
  }
}

export default ZoneCalculator;